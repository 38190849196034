<template>
  <div>
    <!--订单状态 -->
    <div class="size20 mrb40 mrt20 flex">
      <div>订单状态：</div>
      <div v-if="tableData.status == 1">待发货</div>
      <div v-if="tableData.status == 2">待收货</div>
      <div v-if="tableData.status == 3">退款中</div>
      <div v-if="![1,2,3].includes(tableData.status)">{{ tableData.status_namecn }}</div>
    </div>
    <!-- 订单信息 -->
    <div class="first_informa">订单信息</div>
    <div class="secend_status">
      <div class="three_informa">
        <!-- 交易信息 -->
        <div style="width: 33.33%; margin-left: 30px">
          <div class="mrb10 mrt10">交易信息</div>
          <div class="coll_order mrb10 flex align-center ">订单编号：{{ tableData.order_sn }} <el-button v-if="tableData.order_sn " @click="copyClick( tableData.order_sn )" type="text">复制</el-button></div>
          <div class="coll_order mrb10 flex align-center ">订单交易号：{{ tableData.transaction_id }} <el-button v-if="tableData.transaction_id" @click="copyClick(tableData.transaction_id)" type="text">复制</el-button></div>
          <div class="coll_order mrb10  flex align-center">创建时间：{{ tableData.created_at }}</div>
          <div class="coll_order mrb10  flex align-center">付款时间：{{ tableData.pay_time }}</div>
        </div>
        <!-- 买家信息 -->
        <div style="width: 33.33%">
          <div class="mrb10 mrt10">买家信息</div>
          <div class="coll_order mrb10 flex align-center ">
            <div>昵称：</div>
            <div v-if="tableData.user">
              <div>{{ tableData.user.nickname }}</div>
            </div>
          </div>
          <div class="coll_order mrb10  flex align-center ">用户ID： <div v-if="tableData.user">{{ tableData.user.id }}</div>
          </div>
          <div class="coll_order  mrb10  flex align-center ">下单次数：<div v-if="tableData.user">{{ tableData.total_num}}</div>
          </div>
        </div>
        <!-- 物流信息 -->
        <div style="width: 33.33%">
          <div class="mrb10 mrt10">物流信息</div>
          <div class="coll_order1 mrb10 flex align-center ">
            <div class="white-space">
              收货地址：
            </div>
            <div class="" style="width: 400px;">
              {{ tableData.consignee }},
              {{tableData.mobile }},
              {{ tableData.address }}
              <el-button @click="copyClick(tableData.consignee + tableData.mobile + tableData.address)" type="text">复制</el-button>
            </div>
          </div>

          <div class="coll_order flex  mrb10 align-center " v-if="tableData.order_logistics">物流公司名称：<div>
              {{ tableData.order_logistics.logistics_company }}
            </div>
          </div>
          <div class="coll_order mrb10 flex align-center ">
            运送方式：<div v-if="tableData.type == 0">快递</div>
          </div>

          <div class="coll_order mrb10 flex align-center ">
            <div class="flex flex align-center">运单号：<div v-if="tableData.order_logistics">{{ tableData.order_logistics.logistics_no }}</div>
            </div>
            <el-button @click="after_sale(tableData.id)" type="text">查看物流信息</el-button>
          </div>

        </div>
      </div>
    </div>
    <!-- 商品表单 -->
    <div class=" bg-white">
      <el-table :data="tableDataList" border style="width: 100%">
        <el-table-column label="商品信息" width="300" class="box">
          <template slot-scope="scope">
            <div class="table_col" v-for="(i,index) in scope.row.order_goods" :key="index">
              <div class="flex align-center">
                <div class="imgSty">
                  <el-image style="width: 70px; height: 70px" :src="i.cover" :preview-src-list="[i.cover]" fit="fill"></el-image>
                </div>
                <div class="info_describe">
                  <div class="mrb20">{{ i.goods_name }}</div>
                  <div>
                    {{ i.sku_name }}
                  </div>
                </div>
              </div>
            </div>

          </template>
        </el-table-column>
        <el-table-column min-width="100" label="单价" align="center">
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.order_goods" :key="index" class="table_col">
              <p>{{ item.price }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="100" label="数量" align="center">
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.order_goods" :key="index" class="table_col">
              <p>{{ item.goods_num }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="100" label="交易状态" align="center">
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.order_goods" :key="index" class="table_col">
              <div v-if="item.refund_status ==0">正常</div>
              <div v-if="item.refund_status ==1">申请退款中</div>
              <div v-if="item.refund_status ==2">退款成功</div>
              <div v-if="item.refund_status ==3">拒绝退款</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="coupon_money" min-width="100" label="优惠" align="center">
        </el-table-column>
        <el-table-column prop="total_amount" valign="top" label="商品总价" align="center">
          <template slot-scope="scope">
            <div class="flex-column justify-center">
              <div> {{ scope.row.total_amount }}</div>
              <div> 快递费：{{ scope.row.delivery }}</div>
              <div v-if="scope.row.delivery_type == 0">包邮</div>
              <div v-if="scope.row.delivery_type == 1">到付</div>
              <div v-if="scope.row.delivery_type == 2">统一价格</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="mrt20">
        订单备注：{{ tableData.remark }}
      </div>
    </div>
    <div class="size20 mrt20 flex justify-end mrr20" style="width: 100%;">
      <div class="mrr10">实收款</div>
      <div class="red_color">￥{{  tableData.amount }}</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      shipmentsForm: {
        order_id: "",
        logistics_no: "",
        logistics_type: "",
      },
      tableNewData: [],
      hoverOrderArr: [],
      OrderIndexArr: [],
      tableData: {},
      tableDataList: [],
    };
  },

  created() {
    let order_id = this.$route.query.id;
    this.getorderDetail(order_id);
  },
  mounted() {},
  computed: {},

  methods: {
    async getorderDetail(order_id) {
      const { data } = await this.$Api.orderOrderDetail({
        order_id: order_id,
      });
      this.tableData = data;
      this.tableDataList = [data];
      // console.log("获取的详情列表", this.tableData);
    },
    copyClick(context) {
      navigator.clipboard
        .writeText(context)
        .then(() => {
          this.$message.success("复制成功");
        })
        .catch((err) => {
          this.$message.error("复制失败");
        });
    },
    after_sale(id) {
      this.$router.push({
        path: "/order/LogisticsInfo?id=" + id,
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.header {
  height: 70px;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  line-height: 70px;
  padding-left: 20px;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
}
.first_status {
  width: 100%;
  font-size: 20px;
  line-height: 300px;
  font-weight: 500;
  background-color: rgb(247, 248, 250);
  padding-left: 40px;
}
.secend_status {
  width: 100%;
  font-size: 20px;
  background-color: rgb(247, 248, 250);
}
.first_informa {
  height: 70px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 70px;
  background-color: rgb(255, 255, 255);
  padding-left: 40px;
}
.secend_informa {
  height: 470px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 70px;
  background-color: rgb(255, 255, 255);
  padding-left: 20px;
}
.three_informa {
  display: flex;
  height: 250px;
  justify-content: space-around;
}
.coll_order {
  height: 30px;
  font-size: 16px;
  color: rgb(115, 115, 116);
}
.coll_order1 {
  font-size: 16px;
  color: rgb(115, 115, 116);
}
.info_box {
  display: flex;
  flex-direction: row;
}
.info_box_img {
  width: 30%;
  background-color: aqua;
}
.text {
  margin-left: 10px;
  font-size: 16px;
}
.attr {
  margin-left: 10px;
  font-size: 16px;
  color: rgb(151, 151, 151);
}
</style>
<style scoped>
.content {
  padding: 20px;
}
.el-table--enable-row-transition /deep/ .cell {
  /* padding: 20px 0; */

  padding: 0;
  padding-top: 15px;
}
.item {
  width: 100vw;
  background: #f1f1f1;
  position: absolute;
  top: 0;
  left: -55px;
  z-index: 1;
  height: 30px;
  line-height: 30px;
}
</style>
<style scoped>
.imgSty {
  width: 100px;
  display: block;
  padding-left: 10px;
}
.table_col {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid #ebeef5;
}
.table_col:last-child {
  border-bottom: 1px solid transparent;
}

.content >>> .el-table--border td:nth-child(-n + 4) {
  border-right: none !important;
}
.content >>> .el-table--border td:nth-child(2) {
  border-left: 1px solid #ebeef5;
}
/* .content /deep/ .el-table__row td:nth-child(n + 6) .cell {
  position: absolute !important;
  top: 100px !important;
  left: 50%;
  transform: translateX(-50%);
} */
.content /deep/ .el-table__row td:nth-child(1) .cell {
  position: absolute !important;
  top: 1% !important;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  /* background: red; */
}
.pb_sty span {
  background: #1dc8de;
  padding: 2px 8px;
  border-radius: 4px;
  color: #fff;
}

.mesFont p {
  margin-block-start: 0;
  margin-block-end: 0;
  padding: 0 10px;
}
.mesFont p:nth-child(2) {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
/* .el-table__row:hover .item {
  background-color: #eaf8ff;
} */
</style>